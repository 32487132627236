var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px","content-class":"auth-dialog-wrapper"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"auth-dialog"},[_c('div',{staticClass:"auth-dialog-header"},[_c('div',{staticClass:"header-logo-container"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":"/site_images/logo_honely.png","alt":"Honely"}})]),_c('button',{staticClass:"btn-close",on:{"click":function($event){return _vm.hideDialog()}}},[_c('i',{staticClass:"fa fa-times",staticStyle:{"color":"black"}})])])]),_c('div',{staticClass:"auth-dialog-content"},[(_vm.form_type !== 'forgot-password' && (_vm.form_type === 'login' || _vm.signup_step === 1))?_c('btn-switch',{attrs:{"options":[{
            value: 'login',
            label: 'Log In',
          }, {
            value: 'signup',
            label: 'Sign Up',
          }],"size":"sm","value":_vm.form_type,"change":_vm.onChangeFormType}}):_vm._e(),_c('div',{staticStyle:{"margin-top":"25px"}}),(_vm.form_type === 'login')?_c('login-form',{attrs:{"bus":_vm.busLoginForm,"redirectPath":_vm.redirectPath},on:{"stepForgot":function($event){return _vm.onChangeFormType('forgot-password')}}}):_vm._e(),(_vm.form_type === 'signup')?_c('signup-form',{attrs:{"step":_vm.signup_step,"redirectPath":_vm.redirectPath},on:{"stepNext":function($event){return _vm.signupStepNext()},"stepEnd":function($event){return _vm.signupStepEnd()}}}):_vm._e(),(_vm.form_type === 'forgot-password')?_c('forgot-password-form',{on:{"closeOverlay":function($event){return _vm.onChangeFormType('login')}}}):_vm._e()],1)])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }